import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { adminApi } from '../constants/inclub-ribera-admin.constant';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  URL = `${adminApi}/login`;
  userSubject = new BehaviorSubject<any>( null );

  constructor(private http: HttpClient, private router: Router) {}

  getLogin(email: string, password: string): Observable<any> {
    const params = {
      email: email,
      password: password
    };
    return this.http.post( this.URL, params).pipe(
      map( (resp: any) => {
        console.log('resp login', resp)
        localStorage.setItem('token', resp.token); // Guardar token
        localStorage.setItem('tkbo',resp.externalToken);
        this.userSubject.next(resp.user); // Emitir usuario
        return resp
      }
      )
    );
  }

  logout() {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    this.userSubject.next(null);
    this.router.navigate(['/'])
  }
}
